<template>
    <!-- <div v-if="banner && ((pc && detect == 'pc') || (mobile && detect == 'mobile'))" class="main-visual swiper-container"> -->
    <!-- <div class="main-visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="(slide, index) in banner.slides" :key="index" class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" :style="'background-image:url('+ slide?.image +');'"></div>
                        <div class="main-visual__con">
                            <h3 class="main-visual__tit">{{ slide?.meta?.title }}</h3>
                            <div class="main-visual__info">
                                <ul>
                                    <li>{{ slide?.meta?.location }}</li>
                                    <li>{{ slide?.meta?.date?.toDate() }}</li>
                                </ul>
                            </div>
                            <v-btn v-if="slide?.url" :to="slide?.isOuterLink ? undefined : slide?.url" :href="slide?.isOuterLink ? slide?.url : undefined" :target="slide?.isOuterLink ? '_blank': ''" small text color="white" class="main-visual__btn pa-4 ml-n4">VIEW MORE <i class="icon icon-bar white mt-2 ml-6"></i></v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-control">
                <div class="swiper-prev"><i class="icon icon-arrow-left"></i></div>
                <div class="swiper-pagination"></div>
                <div class="swiper-next"><i class="icon icon-arrow-right"></i></div>
            </div>
        </div>
    </div> -->

    <div class="main-visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" style="background-image:url(/images/main/v1.png);"></div>
                        <v-container class="container--large">
                            <div>
                                <div class="mb-30 mb-md-40">
                                    <h3 class="tit line-height-13"><span class="primary--text">안전한 디지털 보안기술</span>의 <br/>파트너 에프원시큐리티</h3>
                                </div>
                                <div>
                                    <div class="font-size-18 font-size-md-24 font-weight-light">정보보호 컨설팅부터 솔루션까지 에프원시큐리티가 <br class="d-none d-md-block"/>여러분의 정보를 함께 보호하겠습니다.</div>
                                </div>
                            </div>
                        </v-container>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" style="background-image:url(/images/main/v2.png);"></div>
                        <v-container class="container--large">
                            <div>
                                <div class="mb-30 mb-md-40">
                                    <h3 class="tit line-height-13" style="word-break: break-all;"><span class="primary--text">정보보호</span> 전문서비스기업 <br/>에프원시큐리티</h3>
                                </div>
                                <div>
                                    <div class="font-size-18 font-size-md-24 font-weight-light">에프원시큐리티는 과학기술정보통신부에서 지정된 <br class="d-none d-md-block"/>정보보호전문서비스기업 입니다.</div>
                                </div>
                            </div>
                        </v-container>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img" style="background-image:url(/images/main/v3.png);"></div>
                        <v-container class="container--large">
                            <div>
                                <div class="mb-30 mb-md-40">
                                    <h3 class="tit line-height-13"><span class="primary--text">Web Security</span>를 위한 <br/>새로운 기준 에프원시큐리티</h3>
                                </div>
                                <div>
                                    <div class="font-size-18 font-size-md-24 font-weight-light">UWSS는 글로벌 클라우드 보안서비스를 실현하는 <br class="d-none d-md-block"/>All-in-One SECaaS 플랫폼입니다.</div>
                                </div>
                            </div>
                        </v-container>
                    </div>
                </div>
            </div>
            <div class="swiper-control">
                <div class="swiper-prev"><i class="icon icon-arrow-left"></i></div>
                <v-divider vertical class="align-self-center mx-10" style="height:16px;"></v-divider>
                <div class="swiper-next"><i class="icon icon-arrow-right"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import detect from "@/plugins/detect";
export default {
    props: {
        pc: Boolean,
        mobile: Boolean,
    },
    data() {
        return {
            detect,
            banner: null,
            visuals: [
                { link: "javascript:", pc: "/images/main/v1.jpg", mo: "/images/main/v1-mo.jpg" },
                { link: "javascript:", pc: "/images/main/v2.jpg", mo: "/images/main/v2-mo.jpg" },
                { link: "javascript:", pc: "/images/main/v3.jpg", mo: "/images/main/v3-mo.jpg" },
            ],
            swiper: undefined,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { banner } = await api.v1.banners.get({ code: this.$attrs.code });
            this.banner = banner;

            this.$nextTick(() => {
                this.swiper = new Swiper(".main-visual .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    // pagination: {
                    //     el: ".main-visual .swiper-pagination",
                    //     type: "custom",
                    //     renderCustom: function(swiper, current, total) {
                    //         function numberAppend(n) {
                    //             return n < 10 ? "0" + n.toString() : n.toString();
                    //         }
                    //         return '<span class="swiper-pagination-current">' + numberAppend(current) + '</span> / <span class="swiper-pagination-total">' + numberAppend(total) + "</span>";
                    //     },
                    // },
                    navigation: {
                        nextEl: ".main-visual .swiper-next",
                        prevEl: ".main-visual .swiper-prev",
                    },
                });
            });
        },
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
