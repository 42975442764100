import axios from "@/plugins/axios";

let url = "/api/console/fairs";

export default {
    gets(_fair, data) {
        return axios.get(`${url}/${_fair}/matchings`, data).then(result => result.data);
    },
    put(_fair, _matching, data) {
        return axios.put(`${url}/${_fair}/matchings/${_matching}`, data).then(result => result.data);
    },
}
