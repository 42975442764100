import axios from "@/plugins/axios";
import CryptoAES from '@/plugins/crypto-aes';

export default {
    getRefreshToken({ username, password }){
        var basicToken = "Basic " + CryptoAES.encrypt( new Buffer(`${username}:${password}`).toString('base64') );

        return axios.get(`/api/auth/refreshToken`, {
            headers: { "Authorization": basicToken }
        }).then(result => result.data);
    },
    getAccessToken(refreshToken){
        var bearerToken = `Bearer ${refreshToken}`;

        return axios.get(`/api/auth/accessToken`, {
            headers: { "Authorization": bearerToken }
        }).then(result => result.data);
    }
}
