<template>
    <div class="main-news-slide">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="board in boards" :key="board._id" class="swiper-slide" @click="openWindow(board.meta.href)">
                    <div class="main-news-slide__con">
                        <div class="border rounded pa-20 px-md-36 py-md-50">
                            <div class="mb-20 mb-md-34">
                                <v-chip outlined color="primary">NEWS</v-chip>
                            </div>
                            <div class="mb-10 mb-md-12">
                                <div class="main-news-slide__tit font-size-18 font-size-lg-22 font-weight-bold">{{ board.subject }}</div>
                            </div>
                            <div>
                                <div class="main-news-slide__txt font-size-16 font-size-md-18 grey-7--text">{{ board.summary }}</div>
                            </div>
                            <div class="mt-20 mt-md-34">
                                <div class="font-size-14 font-size-md-16 grey-a--text">{{ board.createdAt.toDate() }}</div>
                            </div>
                        </div>
                            <v-btn fab color="primary"><i class="icon icon-arrow-right-white"></i></v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import api from "@/api";
export default {
    props: {},
    data() {
        return {
            swiper: undefined,
            headers: {
                limit: 6,
            },
            filter: {
                code: "news",
                limit: 6
            },
            boards: null
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
            let { boards } = await api.v1.boards.gets({
                headers: { ...this.headers },
                params: { ...this.filter}
            })
            this.boards = boards
            await this.render()
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async render() {
            this.$nextTick(() => {
                this.swiper = new Swiper(".main-section--news .swiper-container", {
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 20,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    // pagination: {
                    //     el: ".main-section--news .swiper-pagination",
                    //     clickable: true,
                    // },
                    navigation: {
                        prevEl: ".main-section--news .swiper-prev",
                        nextEl: ".main-section--news .swiper-next",
                    },
                    breakpoints: {
                        576: {
                            spaceBetween: 30,
                            slidesPerView: 2,
                        },
                        1024: {
                            spaceBetween: 36,
                            slidesPerView: 3,
                        },
                    },
                });
            });
        },
        openWindow(url) {
            window.open(url);
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
