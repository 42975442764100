import axios from "@/plugins/axios";

let url = "/api/console/setting";

export default {
    get(){
        return axios.get(url).then(result => result.data);
    },
    put(data){
        return axios.put(url, data).then(result => result.data);
    }
}
