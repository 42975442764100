import axios from "@/plugins/axios";

let url = "/api/v1/boards";

export default {
    gets(_board, data){
        return axios.get(`${url}/${_board}/comments`, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${_board}/comments/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(`${url}/${_board}/comments`, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${_board}/comments/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${_board}/comments/${data._id}`).then(result => result.data);
    }
}
