<template>
    <div class="header-search">
        <div class="header-search__form">
            <v-text-field v-model="searchKeyword" outlined hide-details placeholder="검색어를 입력하세요" @keydown.enter="search" />
            <v-btn large icon class="transparent" @click="search"><i class="icon icon-search"></i></v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        keyword: { type: String, default: null },
    },
    data: () => ({
        searchKeyword: null,
    }),
    mounted() {
        this.init();
    },
    watch: {
        "$route.query.searchKeyword"() {
            this.init();
        },
    },
    methods: {
        init() {
            this.searchKeyword = this.$route.query.searchKeyword || null;
        },
        search() {
            let path = "/auction-search";
            path = this.searchKeyword ? `${path}/${this.searchKeyword}` : path;
            if (this.$route.path != path) this.$router.push(path);
        },
    },
};
</script>

<style></style>
