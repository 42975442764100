<template>
    <v-btn v-scroll="onScroll" @click="$vuetify.goTo(target, options)" fab fixed bottom right color="white" transition="slide-x-transition" class="scroll-top">
        <i class="icon icon-arrow-up"></i>
    </v-btn>
</template>

<script>
export default {
    data(){
        return {
            type: 'number',
            number: 0,
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic',

            show: false,
        };
    },
    mounted(){
    },
    methods: {
        onScroll : function() {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            var scrollTopBtn = document.querySelector('.scroll-top');
            if(scrollTopBtn !== null){
                if(scrollTop > window.innerHeight/2){
                    scrollTopBtn.classList.add('active');
                }else {
                    scrollTopBtn.classList.remove('active');
                }

                var innerHeight = window.innerHeight;
                var scrollHeight = document.body.scrollHeight;
                if (scrollTop + innerHeight >= scrollHeight) {
                    scrollTopBtn.classList.add('bottom');
                } else {
                    scrollTopBtn.classList.remove('bottom');
                }
            }
        },
    },
    computed: {
        target : function() {
            const value = this[this.type]
            if (!isNaN(value)) return Number(value)
            else return value
        },
        options : function() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            }
        },
    },
}
</script>