import axios from "@/plugins/axios";

let url = "/api/console/fairs";

export default {
    post(_fair, locale, file){
        var formData = new FormData(); formData.append("thumb", file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_fair}/thumb/${locale}`, formData, { headers }).then(result => result.data);
    },
    delete(_fair, locale){
        return axios.delete(`${url}/${_fair}/thumb/${locale}`).then(result => result.data);
    }
}
