import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        // 회사소개 - 비전
        path: "/about/vision",
        component: () => import("../pages/client/sub/about/AboutVisionPage.vue"),
        props: true,
    },
    {
        // 회사소개 - 인사말
        path: "/about/greeting",
        component: () => import("../pages/client/sub/about/AboutGreetingPage.vue"),
        props: true,
    },
    {
        // 회사소개 - organization
        path: "/about/organization",
        component: () => import("../pages/client/sub/about/AboutOrganizationPage.vue"),
        props: true,
    },
    {
        // 회사소개 - history
        path: "/about/history",
        component: () => import("../pages/client/sub/about/AboutHistoryPage.vue"),
        props: true,
    },
    {
        // 회사소개 - recruitment
        path: "/about/recruitment",
        component: () => import("../pages/client/sub/about/AboutRecruitmentPage.vue"),
        props: true,
    },
    {
        // 회사소개 - contact
        path: "/about/contact",
        component: () => import("../pages/client/sub/about/AboutContactPage.vue"),
        props: true,
    },
    {
        // 컨설팅 사업부 
        path: "/business/consulting",
        component: () => import("../pages/client/sub/business/BusinessConsultingPage.vue"),
        props: true,
    },
    {
        // 솔류션 사업부
        path: "/business/solution",
        component: () => import("../pages/client/sub/business/BusinessSolutionPage.vue"),
        props: true,
    },
    {
        // 블록체인 사업부
        path: "/business/blockchain",
        component: () => import("../pages/client/sub/business/BusinessBlockchainPage.vue"),
        props: true,
    },
    {
        // 솔루션
        path: "/solution/uwss",
        component: () => import("../pages/client/sub/solution/SolutionUwssPage.vue"),
        props: true,
    },
    {
        // 솔루션
        path: "/solution/webcastle",
        component: () => import("../pages/client/sub/solution/SolutionWebcastlePage.vue"),
        props: true,
    },
    {
        // 솔루션
        path: "/solution/wsfinder",
        component: () => import("../pages/client/sub/solution/SolutionWsfinderPage.vue"),
        props: true,
    },
    {
        // 솔루션
        path: "/solution/wmds",
        component: () => import("../pages/client/sub/solution/SolutionWmdsPage.vue"),
        props: true,
    },
    {
        // 소식 - News
        path: "/news",
        component: () => import("../pages/client/board/News.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    // {
    //     path: "/common/kcp/cert/request",
    //     component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
    //     props: true,
    // },
    // {
    //     path: "/common/kcp/cert/response",
    //     component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
    //     props: true,
    // },
    // {
    //     // 가이드
    //     path: "/guide",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/Guide.vue"),
    //     props: true,
    // },
    // {
    //     // 로그인
    //     path: "/login",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
    //     props: true,
    // },
    // {
    //     // 비밀번호 찾기
    //     path: "/login/find-password",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
    //     props: true,
    // },
    // {
    //     // 아이디 찾기
    //     path: "/login/find-id",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입
    //     path: "/join",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/type",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/form",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 완료
    //     path: "/join/complete",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
    //     props: true,
    // },
    // {
    //     // 응찰/낙찰 내역
    //     path: "/mypage/bids-:type",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/BidsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 응찰/낙찰 내역
    //     path: "/mypage/bids-:type/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/BidsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청내역 상세
    //     path: "/mypage/consignment-list/:_form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentViewPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청내역 목록
    //     path: "/mypage/consignment-list",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 작품문의 상세
    //     path: "/mypage/private-list/:_form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/PrivateViewPage.vue"),
    //     props: true,
    // },
    // {
    //     // 작품문의 목록
    //     path: "/mypage/private-list",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/PrivateListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청
    //     path: "/mypage/consignment-form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentFormPage.vue"),
    //     props: true,
    // },
    // {
    //     // 1:1 문의
    //     path: "/mypage/question",
    //     component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
    //     children: [...QuestionRoutes],
    //     props: true,
    // },
    // {
    //     // 회원 정보 상세
    //     path: "/mypage/myinfo",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 정보 변경
    //     path: "/mypage/myinfo/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Search 페이지
    //     path: "/auction-search/",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/SearchPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Search 페이지
    //     path: "/auction-search/:keyword",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/SearchPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Hall 페이지
    //     path: "/auction-halls/:hall",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionHallPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Hall 페이지 - Group
    //     path: "/auction-halls/:hall/groups/:group",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionHallPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Work 페이지
    //     path: "/auction-works/:_work",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionWorkPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction - 지난경매
    //     path: "/auction-results",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionResultsPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction - 지난경매
    //     path: "/auction-results/:hall",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionResultsPage.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sales
    //     path: "/private-sales",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/private-sales/PrivateSalesPage.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sales
    //     path: "/private-sales/:tab",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/private-sales/PrivateSalesPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 위탁안내
    //     path: "/how-to/consignment",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToConsignmentPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 응찰안내
    //     path: "/how-to/bid",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToBidPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 응찰안내
    //     path: "/how-to/:tab",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToBidPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - FAQ
    //     path: "/center/faqs",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Collection
    //     path: "/collection",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/CollectionPage.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Museum Services
    //     path: "/museum-services",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/MuseumServicesPage.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Valuations
    //     path: "/valuations",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/ValuationsPage.vue"),
    //     props: true,
    // },
    // {
    //     // About - About & Mission
    //     path: "/about-mission",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/AboutMissionPage.vue"),
    //     props: true,
    // },
    // {
    //     // About - 공지사항
    //     path: "/center/notice",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // About - 공지사항
    //     path: "/center/notice/:_notification",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // About - 언론보도
    //     path: "/board/press",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Press.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // About - 위치안내
    //     path: "/location",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/LocationPage.vue"),
    //     props: true,
    // },
    // {
    //     // 캐터옥션 유튜브
    //     path: "/board/youtube",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Youtube.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 캐터옥션 리포트
    //     path: "/board/report",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Report.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // Forms & Archives
    //     path: "/board/forms",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Forms.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 경매약관
    //     path: "/auction-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/AuctionPolicyPage.vue"),
    //     props: true,
    // },
    // {
    //     // 서비스이용약관
    //     path: "/service-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/ServicePolicyPage.vue"),
    //     props: true,
    // },
    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
        props: true,
    },
    // {
    //     // 문의 및 제휴
    //     path: "/form/inquiry-partner",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청문의
    //     path: "/form/consignment-sale",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/ConsignmentSale.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sale 문의
    //     path: "/form/private-sale",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/PrivateSale.vue"),
    //     props: true,
    // },
    // {
    //     // 개인정보처리방침
    //     path: "/about/press",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/board/AuthorBoard.vue"),
    //     props: true,
    // },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["root","console"],
    },
    {
        // 관리자 - 대시보드
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
    },
    // {
    //     // 관리자 - 옥션 프로그램
    //     path: "/console/auction-programs",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/auction/ProgramListPage.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 옥션 작품
    //     path: "/console/auction-works",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/auction/WorkListPage.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 옥션 작가
    //     path: "/console/auction-authors",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/auction/AuthorListPage.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    // {
    //     // 관리자 - 폼메일관리
    //     path: "/console/forms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    // },
    // {
    //     // 관리자 - 폼메일관리
    //     path: "/console/forms/:_form",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    // },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    // {
    //     // 관리자 - 공지사항
    //     path: "/console/center/notifications",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
    //     props: true,
    // },
    // {
    //     // 관리자 - FAQ
    //     path: "/console/center/faqs",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
    //     props: true,
    // },
    // {
    //     // 관리자 - 1:1문의
    //     path: "/console/center/questions",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
    //     props: true,
    // },

    {
        // 관리자 - 상품관리
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductList.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 생성
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 복사
        path: "/console/shop/products/copy",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 상품 view
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductView.vue"),
        props: true,
    },
    {
        // 관리자 - 카테고리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/CategoryList.vue"),
        props: true,
    },

    // {
    //     // 관리자 - 서비스 이용약관
    //     path: "/console/terms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/terms/TermsList.vue"),
    //     props: true,
    // },
    // {
    //     // 관리자 - 배너
    //     path: "/console/banners",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
    //     props: true,
    // },
    // {
    //     // 관리자 - 배너
    //     path: "/console/banners/create",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
    //     props: true,
    // },
    {
        // 관리자 - 배너
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 팝업
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    {
        // 관리자 - 설정
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = undefined;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        if (scrollTo != undefined) return {
            selector: scrollTo,
            behavior: "smooth"
        }
    },
    
});

router.beforeEach((to, from, next) => {
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("accessToken") ? `Bearer ${sessionStorage.getItem("accessToken")}` : "";
    axios.defaults.headers.common["Accept-Language"] = i18n.locale;

    next();
});

export default router;
