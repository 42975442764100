import axios from "@/plugins/axios";
import products from "./products";
import sellers from "./sellers";
import buyers from "./buyers";

let url = "/api/v1/fairs";

export default {
    sellers,
    buyers,
    products,
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
}
