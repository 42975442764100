<template>
    <footer class="footer">
        <v-container class="py-20 py-md-34">
            <v-row align="center" class="row--small">
                <v-col order="2" order-md="1">
                    <h1 class="footer-tit">F1 Security</h1>
                    <div class="mt-6 mt-md-10">
                        <ul class="footer-info">
                            <li><span>주소</span>08513 서울 금천구 벚꽃로 234 에이스하이엔드타워6차 1402호</li>
                            <li><span>문의번호</span>070-4640-3030</li>
                            <li><span>이메일</span>sales@f1security.co.kr</li>
                        </ul>
                    </div>
                </v-col>
                <v-col cols="12" md="auto" order="1" order-md="2">
                    <div class="mb-10 mb-md-0 w-50 w-md-100">
                        <v-img max-width="216"  src="/images/logo-dark.svg"></v-img>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-divider style="border-color:#363b49;"></v-divider>
        <v-container class="py-20 py-md-30">
            <v-row align="center" class="row--small">
                <v-col class="d-flex mb-4 mb-lg-0">
                    <ul class="footer-link">
                        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                    </ul>
                </v-col>
                <v-col cols="12" md="auto">
                    <p class="copyright">© 2022. F1Security Corp. all rights reserved.</p>
                </v-col>
            </v-row>
        </v-container>
        <u-scroll-top></u-scroll-top>
    </footer>
</template>

<script>
import UScrollTop from "@/components/dumb/u-scroll-top.vue";
import api from "@/api";

export default {
    components: {
        UScrollTop,
    },
    data() {
        return {
            setting: null,
        };
    },
    computed: {
        info() {
            return this.setting?.footer?.info || [];
        },
        sns() {
            return (this.setting?.footer?.sns || []).filter(({ shows }) => shows);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { setting } = await api.v1.setting.get();
            this.setting = setting;
        },
    },
};
</script>

<style scoped>
.footer {
    font-size: 1.4rem;
    color: #80838f;
    background-color: #1f232f;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.footer-tit{
    font-size: 1.4rem;
    font-weight: 700;
    color: #b0b3c1;
    margin: 0;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .footer-tit{
        font-size: 1.6rem;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
.footer-link {
    display: flex;
    flex-wrap: wrap;
    margin: -4px -10px;
}
.footer-link li {
    position: relative;
    padding: 4px 10px;
}
.footer-link li::before {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 1px;
    height: 1px;
    background-color: #80838f;
}
.footer-link li:first-child::before {
    display: none;
}
.footer-link li a {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2;
    color: #80838f;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-link {
        margin: 0 -14px;
    }
    .footer-link li {
        padding: 0 14px;
    }
    .footer-link li::before {
        display: block;
    }
    .footer-link li a {
        font-size: 1.4rem;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.footer-info {
    font-size: 1.2rem;
    margin: -2px -6px;
    overflow: auto;
}
.footer-info::after {
    content: "";
    clear: both;
    display: table;
}
.footer-info li {
    float: left;
    display: inline-block;
    padding: 2px 6px;
}
.footer-info li > span {
    margin-right: 4px;
    color: #b0b3c1;
}
.footer-info__tit {
    font-weight: 500;
    color: #b0b3c1;
    margin-bottom: 2px;
}
@media (min-width: 576px) {
    .footer-info {
        font-size: 1.4rem;
    }
}
@media (min-width: 768px) {
    .footer-info {
        margin: -4px -14px;
    }
    .footer-info li {
        padding: 4px 14px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.copyright {
    font-size: 1.2rem;
    color: #80838f;
}
.familysite {
    width: 200px;
    font-size: 1.4rem;
    border: 1px solid #b0b3c1;
    border-radius: 0;
}
.familysite .v-input__slot {
    height: 36px !important;
}
.familysite .v-text-field--outlined legend {
    border: none;
}
.v-application .familysite,
.familysite input::placeholder,
.familysite .v-select__selection--comma,
.familysite .mdi:before {
    color: #b0b3c1 !important;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .copyright {
        font-size: 1.4rem;
    }
    .familysite .v-input__slot {
        height: 44px !important;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
