<template>
    <section class="main-section">
        <v-container>
            <div :class="'tit-wrap flex-nowrap align-center '+ titleClass">
                <h2 class="tit tit--lg">{{ title }}</h2>
                <slot name="title-actions" />
            </div>
            <slot />
        </v-container>
        <slot name="immersive" />
    </section>
</template>

<script>
export default {
    props: {
        title: String,
        titleClass: String,
    },
    data(){
        return{
        }
    },
};
</script>
<style scoped>
</style>