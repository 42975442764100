import axios from "@/plugins/axios";

let url = "/api/v1/me/orders";

export default {
    gets(){
        return axios.get(url).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    }
}
