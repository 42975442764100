<template>
    <client-page class="app--main">

        <!-- S: 메인 비주얼 -->
        <!-- <main-visual code="main-pc" px></main-visual> -->
        <main-visual></main-visual>
        <!-- E: 메인 비주얼 -->

        <!-- S: 제품 및 솔루션 -->
        <main-section title="제품 및 솔루션" titleClass="text-center" class="main-section--solution">
            <div data-aos="fade-up">
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <div class="d-inline-block line-height-0 rounded overflow-hidden"><img src="/images/main/main-solution-img.png" alt="이미지"></div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="pl-lg-80">
                            <div class="tit-wrap mb-16">
                                <h2 class="tit tit--md">보안컨설팅</h2>
                            </div>
                            <p class="font-size-16 font-size-md-22 grey-7--text">최신 동향 및 법률을 반영한 컨설팅 방법론으로 최고의 <br class="d-none d-lg-block"/>정보보호 서비스를 제공합니다.</p>
                            <div class="mt-40 mt-md-60">
                                 <!-- :to="{ path: '/business/consulting', hash: '#consulting' }" -->
                                <v-btn to="/business/consulting#consulting" text color="primary" class="v-btn--none-active-bg pa-0 h-auto">더 알아보기<i class="icon icon-arrow-right-primary ml-12"></i></v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div data-aos="fade-up" data-aos-delay="100" class="mt-60 mt-md-80 mt-lg-110">
                <v-row align="center">
                    <v-col cols="12" md="6" order="1" order-md="2">
                        <div class="d-inline-block line-height-0 rounded overflow-hidden"><img src="/images/main/main-solution-img2.png" alt="이미지"></div>
                    </v-col>
                    <v-col cols="12" md="6" order="2" order-md="1">
                        <div class="pl-lg-80">
                            <div class="tit-wrap mb-16">
                                <h2 class="tit tit--md">정보보안솔루션</h2>
                            </div>
                            <p class="font-size-16 font-size-md-22 grey-7--text">UWSS All in One SECaaS 솔루션 웹 해킹 예방, <br class="d-none d-lg-block"/>탐지, 대응을 하나의 웹보안 서비스(Unified Web <br class="d-none d-lg-block"/>Security Service)를 제공합니다.</p>
                            <div class="mt-40 mt-md-60">
                                <v-btn to="/solution/uwss#uwss" text color="primary" class="v-btn--none-active-bg pa-0 h-auto">더 알아보기<i class="icon icon-arrow-right-primary ml-12"></i></v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </main-section>
        <!-- E: 제품 및 솔루션 -->

        <!-- S: 사업분야 -->
        <main-section title="사업분야" titleClass="text-center" class="main-section--business">
            <div data-aos="fade-up">
                <v-divider class="white" style="opacity:.5;"></v-divider>
                <router-link to="/business/consulting" class="main-business-con">
                    <v-row align="center">
                        <v-col cols="12" md="2">
                            <i class="icon icon-num-01"></i>
                        </v-col>
                        <v-col cols="12" md="9">
                            <div class="tit-wrap mb-12 mb-md-18">
                                <h2 class="tit tit--sm">컨설팅 사업</h2>
                            </div>
                            <p class="font-size-16 font-size-md-18">서비스는 고객의 정보자산을 각종 위협으로부터 안전하게 보호하기 위하여 Risk 관리를 기반으로 <br class="d-none d-lg-block"/>다양한 분야의 정보보안 컨설팅 서비스를 제공합니다.</p>
                        </v-col>
                        <v-col cols="12" md="1" class="d-none d-md-block">
                            <v-btn fab outlined color="white"><i class="icon icon-arrow-right-white"></i></v-btn>
                        </v-col>
                    </v-row>
                </router-link>
            </div>
            <div data-aos="fade-up" data-aos-delay="100">
                <v-divider class="white" style="opacity:.5;"></v-divider>
                <router-link to="/business/solution" class="main-business-con">
                    <v-row align="center">
                        <v-col cols="12" md="2">
                            <i class="icon icon-num-02"></i>
                        </v-col>
                        <v-col cols="12" md="9">
                            <div class="tit-wrap mb-12 mb-md-18">
                                <h2 class="tit tit--sm">솔루션 사업</h2>
                            </div>
                            <p class="font-size-16 font-size-md-18">UWSS, WMDS 등 소프트웨어 개발, 솔루션 영업을 주요업무로 하며, 제품의 설치 및 안내, <br class="d-none d-lg-block"/>A/S 서비스, 주기적인 업데이트 등 전반적인 사업을 담당합니다.</p>
                        </v-col>
                        <v-col cols="12" md="1" class="d-none d-md-block">
                            <v-btn fab outlined color="white"><i class="icon icon-arrow-right-white"></i></v-btn>
                        </v-col>
                    </v-row>
                </router-link>
            </div>
            <div data-aos="fade-up" data-aos-delay="200">
                <v-divider class="white" style="opacity:.5;"></v-divider>
                <router-link to="/business/blockchain" class="main-business-con">
                    <v-row align="center">
                        <v-col cols="12" md="2">
                            <i class="icon icon-num-03"></i>
                        </v-col>
                        <v-col cols="12" md="9">
                            <div class="tit-wrap mb-12 mb-md-18">
                                <h2 class="tit tit--sm">블록체인 사업</h2>
                            </div>
                            <p class="font-size-16 font-size-md-18">메타버스 플랫폼의 핵심 기술인 NFT, DID 관련 핵심기술을 개발합니다.</p>
                        </v-col>
                        <v-col cols="12" md="1" class="d-none d-md-block">
                            <v-btn fab outlined color="white"><i class="icon icon-arrow-right-white"></i></v-btn>
                        </v-col>
                    </v-row>
                </router-link>
                <v-divider class="white" style="opacity:.5;"></v-divider>
            </div>
        </main-section>
        <!-- E: 사업분야 -->

        <!-- S: NEWS -->
        <main-section title="NEWS" class="main-section--news">
            <template slot="title-actions">
                <div class="swiper-control">
                    <div class="swiper-prev"><i class="icon icon-arrow-left"></i></div>
                    <v-divider vertical class="align-self-center mx-10" style="height:16px;"></v-divider>
                    <div class="swiper-next"><i class="icon icon-arrow-right"></i></div>
                </div>
            </template>
            <main-news-slide></main-news-slide>
        </main-section>
        <!-- E: NEWS -->


        <!-- <v-row justify="center">
            <v-col cols="12" align="center" class="py-0">
                <span class="subtitle-1 font-weight-bold">다국어 샘플</span> <br>
                <span class="caption">(해당 내용은 테스트하고 지우면되요)</span>
            </v-col>
            <v-col cols="auto" class="py-0">
                <locale-select label="언어변경"></locale-select>
            </v-col>
            <v-col cols="12" align="center">
                <div> 한국어 번역 경로: src/locales/ko.js </div>
                <div> 영어 번역 경로: src/locales/en.js </div>
                <div> 중국어 번역 경로: src/locales/cn.js </div>
                <div class="mt-4"> 사용법 $t() 함수 안에, src/locales/{locale}.js 에서 작성한 json 키워드 나열 </div>
                <div class="font-weight-bold mt-4">JSON 작성법1</div>
                <div>
                    {
                        "key1": "value1"
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법2</div>
                <div>
                    {
                        "key1": {
                            "key2": "value2"
                        }
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법3 - 우리 회사 룰 대략 아래처럼 정하죠 </div>
                <div>
                    {
                        page: {
                            section1: "내용1",
                            section2: {
                                title: "타이틀",
                                content: "내용"
                            }
                        }
                    }
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example1</div>
                    <div class="body-1">$t("common.로그인")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("common.로그인") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example2</div>
                    <div class="body-1">$t("main.section1")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section1") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example3</div>
                    <div class="body-1">$t("main.section2.title")</div>
                    <div class="body-1">$t("main.section2.content")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.title") }}</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.content") }}</div>
                </div>
            </v-col>
        </v-row> -->
        <popup-layer></popup-layer>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MainSection from "@/components/client/main/main-section.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainNewsSlide from "@/components/client/main/main-news-slide.vue";
import PopupLayer from '@/components/client/popup/popup-layer.vue';
import LocaleSelect from "@/components/client/inc/locale-select.vue";
import { BOARD_YOUTUBE_CATEGORIES, BOARD_REPORT_CATEGORIES, BOARD_FORMS_CATEGORIES } from '@/assets/variables';

const categoriesYoutube = Object.values(BOARD_YOUTUBE_CATEGORIES)
const categoriesReport = Object.values(BOARD_REPORT_CATEGORIES)
const categoriesForms = Object.values(BOARD_FORMS_CATEGORIES)

export default {
    components: {
        ClientPage,
        MainSection,
        MainVisual,
        MainNewsSlide,
        PopupLayer,
        LocaleSelect
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data: () => ({
        categoriesYoutube,
        categoriesReport,
        categoriesForms,
    }),
     mounted: function(){
        setTimeout(() => {
            AOS.init({
                once: false,
                offset: 240,
                duration: 500,
                throttleDelay : 100,
                easing : 'ease-in-out',
                anchorPlacement : 'top-center',
            });
        }, 100);
    },
};
</script>