import authors from "./authors";
import files from "./files";
import programs from "./programs";
import works from "./works";

export default {
    authors,
    files,
    programs,
    works,
};
