export const BoardRoutes = [
    {
        path: `/`,
        components: {
            board: () => import(/* webpackChunkName: "board" */ "../components/client/board/board-list.vue")
        },
        props: {
            board: { viewType: "list" }
        }
    },
    {
        path: `create`,
        components: {
            board: () => import(/* webpackChunkName: "board" */ "../components/client/board/board-input.vue")
        },
        props: {
            board: { viewType: "input" }
        }
    },
    {
        path: `:_board`,
        components: {
            board: () => import(/* webpackChunkName: "board" */ "../components/client/board/board-view.vue")
        },
        props: {
            board: route => ({ viewType: "view", _id: route.params._board })
        }
    },
]