<template>
    <div v-if="className == 'sub-tab-wrap'">
        <template v-for="item in gnbs">
            <ul class="tab tab--underline tab--inline" :key="item.title" v-if="item.title == sh">
                <li v-for="child in item.children" :key="child.title" class="tab__li" :class="{'active' : tabActive == child.title}">
                    <a :href="child.path" class="tab__btn"><span>{{child.title}}</span></a>
                </li>
            </ul>
        </template>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className+'__li'" :key="item.title">
                <router-link :to="item.path" :class="className+'__link'"><h2>{{item.title}}</h2></router-link>
            </li>

            <li v-else :class="className+'__li'" :key="item.title">
                <div v-if="isMobile" :class="className+'__link gnb__link--toggle'"><h2>{{item.title}}</h2></div>
                <router-link v-else :to="item.path" :class="className+'__link gnb__link--toggle'"><h2>{{item.title}}</h2></router-link>
                <ul :class="className+'__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{child.title}}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className : String,
        indexNum : String,
        sh : String,
        tabActive : String,        
    },
    data: function(){
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs : [
                {
                    title : "회사소개",
                    path : "/about/vision",
                    children : [
                        {
                            title : "비전",
                            path : "/about/vision",
                        },
                        {
                            title : "인사말",
                            path : "/about/greeting",
                        },
                        {
                            title : "조직도",
                            path : "/about/organization",
                        },
                        {
                            title : "연혁",
                            path : "/about/history",
                        },
                        {
                            title : "인재채용",
                            path : "/about/recruitment",
                        },
                        {
                            title : "Contact",
                            path : "/about/contact",
                        },
                    ]
                },
                {
                    title : "사업분야",
                    path : "/business/consulting",
                    children : [
                        {
                            title : "컨설팅 사업",
                            path : "/business/consulting",
                        },
                        {
                            title : "솔루션 사업",
                            path : "/business/solution",
                        },
                        {
                            title : "블록체인 사업",
                            path : "/business/blockchain",
                        },
                    ]
                },
                {
                    title : "제품 및 솔루션",
                    path : "/solution/uwss",
                    children : [
                        {
                            title : "F1 UWSS",
                            path : "/solution/uwss",
                        },
                        {
                            title : "F1 WEBCASTLE",
                            path : "/solution/webcastle",
                        },
                        {
                            title : "F1 WSFINDER",
                            path : "/solution/wsfinder",
                        },
                        {
                            title : "F1 WMDS",
                            path : "/solution/wmds",
                        },
                    ]
                },
                {
                    title : "소식",
                    path : "/news",
                    children : [
                        {
                            title : "NEWS",
                            path : "/news",
                        },
                    ]
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted: function(){
    },
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    }
};
</script>